<template>
<v-container >
    <AppLayoutHome></AppLayoutHome>

    <div v-if="!$vuetify.breakpoint.mobile">

    <ProyectosViewDesktop/>
  </div>


  <div v-else>
    <ProyectosViewMobile/>
  </div>
</v-container>


</template>

<script>
import AppLayoutHome from '@/layouts/AppLayoutHome'
import ProyectosViewDesktop from './ProyectosViewDesktop'
import ProyectosViewMobile from './ProyectosViewMobile'
export default {
  name: 'ProyectosView',
  components: {
    AppLayoutHome,
    ProyectosViewDesktop,
    ProyectosViewMobile
    
  }
}
</script>