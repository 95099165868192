<template>
<v-container >
<h1>PROYECTOS MOBILE</h1>
</v-container>


</template>

<script>

export default {
  name: 'ProyectosViewMobile',
  components: {    
  }
}
</script>