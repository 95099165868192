<template>
<v-container >
<h1>PROYECTOS DESKTOP</h1>
<v-row>
  <v-col class="col-4 ma-0 pa-0" v-for="n in 12" :key="n">
     <v-card
          class="pa-2"
          tile
          outlined
        >
         {{n}}
        </v-card>
  </v-col>
</v-row>
</v-container>


</template>

<script>

export default {
  name: 'ProyectosViewDesktop',
  components: {    
  }
}
</script>